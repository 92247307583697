import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IntroImg from '../../static/img/mixed-fruits.jpg'

const IndexPage = () => (
  <Layout>
       <header className="masthead text-center text-white" id="intro" style={{backgroundImage:`url(${IntroImg})`}}>
            <div className="masthead-content">
                 <div className="container">
                 <h1 className="masthead-heading mb-0">Orange you glad we're friends!</h1>
                 <h2 className="masthead-subheading mb-0" style={{color:'#ffb502'}}>Thanks for connecting with Juice and Jess. You will receive Juicy Healthy Happiness in your inbox soon!</h2>
               </div>
             </div>
       </header>

      <footer id="footer" className="p-2 text-white text-center mt-5">
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong>Juice & Jess</strong>. All Rights Reserved
          </div>
          <div className="credits">

          </div>
        </div>
      </footer>

  </Layout>
)

export default IndexPage
